<template>
  <div class="container-checkout-personal-data">
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidaName ? 'invalid' : ''"
          class="label-input"
          :style="name ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Nombre</label
        >
        <input
          v-model="name"
          :class="invalidaName ? 'invalid-error' : ''"
          placeholder="Nombre"
          type="text"
          autocomplete="given-name"
          @blur="validateBlur('name')"
          @focus="invalidaName = false"
        />
        <span v-if="invalidaName" class="inavalida-input">{{
          name ? 'Nombre invalido' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidaFamilyName ? 'invalid' : ''"
          class="label-input"
          :style="familyName ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Apellidos</label
        >
        <input
          v-model="familyName"
          :class="invalidaFamilyName ? 'invalid-error' : ''"
          placeholder="Apellidos"
          type="text"
          autocomplete="family-name"
          @blur="validateBlur('familyName')"
          @focus="invalidaFamilyName = false"
        />
        <span v-if="invalidaFamilyName" class="inavalida-input">{{
          familyName ? 'Apellido invalido' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="d-flex w-100">
        <div class="col-4 col-md-5 col-xl-3">
          <div class="input-simple-clover">
            <label
              :class="invalidaTypeDoc ? 'invalid' : ''"
              class="label-input"
              :style="typeDoc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Tipo</label
            >
            <select
              v-model="typeDoc"
              :class="invalidaTypeDoc ? 'invalid-error' : ''"
              type="number"
              class="w-100"
              @blur="validateBlur('typeDoc')"
              @focus="invalidaTypeDoc = false"
            >
              <option :value="''">Tipo</option>
              <option value="CC">CC</option>
              <option value="CE">CE</option>
              <option value="PAS">PAS</option>
              <option value="NIT">NIT</option>
            </select>
            <span v-if="invalidaTypeDoc" class="inavalida-input"
              >Campo requerido</span
            >
          </div>
        </div>
        <div class="col-8 col-md-7 col-xl-9">
          <div class="input-simple-clover">
            <label
              :class="invalidaDoc ? 'invalid' : ''"
              class="label-input"
              :style="doc ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
              >Documento</label
            >
            <input
              v-model="doc"
              :class="invalidaDoc ? 'invalid-error' : ''"
              placeholder="Documento"
              type="number"
              @blur="validateBlur('doc')"
              @focus="invalidaDoc = false"
            />
            <span v-if="invalidaDoc" class="inavalida-input">{{
              doc ? 'Documento invalido' : 'Campo requerido'
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidaTel ? 'invalid' : ''"
          class="label-input"
          :style="tel ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Celular</label
        >
        <input
          v-model="tel"
          :class="invalidaTel ? 'invalid-error' : ''"
          placeholder="Celular"
          type="number"
          autocomplete="tel"
          @blur="validateBlur('tel')"
          @focus="invalidaTel = false"
        />
        <span v-if="invalidaTel" class="inavalida-input">{{
          tel ? 'Telefono invalido' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4 ms-auto">
      <button class="bnt-primary w-100" @click="validateData()">
        Siguiente <img src="@/assets/icons/arrow-right.svg" />
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      name: '',
      invalidaName: false,
      familyName: '',
      invalidaFamilyName: false,
      typeDoc: '',
      invalidaTypeDoc: false,
      doc: '',
      invalidaDoc: false,
      tel: '',
      invalidaTel: false,
    }
  },
  computed: {
    ...mapState('cartAnonimo', [
      'errorAddToCart',
      'msgErrorAddToCart',
      'dataCart',
    ]),
    /* eslint-disable */
    validateName() {
      const re = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;
      return this.name.length > 2
      && re.test(this.name)
    },
    validateFamilyName() {
      const re = /^[a-zA-ZáéíóúÁÉÍÓÚüÜñÑ\s]+$/;
      return this.familyName.length > 2
      && re.test(this.familyName)
    },
    validateTypeDoc() {
      return this.typeDoc
    },
    validateDoc() {
      // if(this.dataCart.tipodoc == 'CC'){
      //     const re1 = /([0-9]){7}/;
      //     return re1.test(this.dataCart.cliente_documento) && this.dataCart.cliente_documento.length >= 7 && this.dataCart.cliente_documento.length <= 10;
      // } else if(this.dataCart.tipodoc == 'CE'){
      //     const re2 = /([0-9]){6}/;
      //     return re2.test(this.dataCart.cliente_documento) && this.dataCart.cliente_documento == 6;
      // } else if(this.dataCart.tipodoc == 'PAS'){
      //     const re3 = /([0-9A-Za-z]){5}/;
      //     return re3.test(this.dataCart.cliente_documento) && this.dataCart.cliente_documento.length >= 5;
      // } else if(this.dataCart.tipodoc == 'NIT'){
      //     return this.dataCart.cliente_documento.length == 9;
      // }
      return this.doc.length > 4 && this.doc.length <= 10
    },
    validateTel() {
      const re = /^3[0-9]{9}/
      return re.test(this.tel) && this.tel.length < 11
    },
  },
  mounted() {
    if (this.dataCart) {
      this.updateData()
    }
  },
  methods: {
    ...mapActions('cartAnonimo', ['updateInfoCart']),
    validateBlur(input) {
      if (input == 'name') {
        this.invalidaName = !this.validateName
      } else if (input == 'familyName') {
        this.invalidaFamilyName = !this.validateFamilyName
      } else if (input == 'typeDoc') {
        this.invalidaTypeDoc = !this.validateTypeDoc
      } else if (input == 'doc') {
        this.invalidaDoc = !this.validateDoc
      } else if (input == 'tel') {
        this.invalidaTel = !this.validateTel
      }
    },
    async validateData() {
      if (
        this.validateName &&
        this.validateFamilyName &&
        this.validateTypeDoc &&
        this.validateDoc &&
        this.validateTel
      ) {
        let data = {
          step: '2',
          cliente_correo: this.dataCart.cliente_correo,
          cliente_nombre: this.name,
          cliente_apellidos: this.familyName,
          tipodoc: this.typeDoc,
          cliente_documento: this.doc,
          cliente_celular: this.tel,
        }
        await this.updateInfoCart(data).then(() => {
          if (!this.errorAddToCart) {
            this.$emit('changeStep', 3)
          }
        })
      } else {
        this.invalidaName = !this.validateName
        this.invalidaFamilyName = !this.validateFamilyName
        this.invalidaTypeDoc = !this.validateTypeDoc
        this.invalidaDoc = !this.validateDoc
        this.invalidaTel = !this.validateTel
      }
    },
    updateData() {
      this.name = this.dataCart.cliente_nombre
      this.familyName = this.dataCart.cliente_apellidos
      this.typeDoc = this.dataCart.tipodoc
      this.doc = this.dataCart.cliente_documento
      this.tel = this.dataCart.cliente_celular
    },
  },
}
</script>
