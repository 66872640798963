<template>
  <div class="container-payment-addi text-center">
    <p>
      <b>
        Finaliza tu compra con ADDI Es simple, rápido y seguro:
      </b>
    </p>
    <p class="mb-4">
      Paga a cuotas con ADDI <br />
      <span style="font-size: 12px">*sujeto a aprobación</span>
    </p>
    <div class="d-flex flex-wrap mb-4">
      <div
        class="mx-1 col-12 col-lg d-flex align-items-center mb-2"
        style="border-radius: 10px; background-color: #E9E9E9"
      >
        <p class="p-3 mb-0 text-center">
          Sin tarjeta de crédito y en minutos.
        </p>
      </div>
      <div
        class="mx-1 col-12 col-lg d-flex align-items-center mb-2"
        style="border-radius: 10px; background-color: #E9E9E9"
      >
        <p class="p-3 mb-0 text-center">
          Paga tu primera cuota un mes después de la compra.
        </p>
      </div>
      <div
        class="mx-1 col-12 col-lg d-flex align-items-center mb-2"
        style="border-radius: 10px; background-color: #E9E9E9"
      >
        <p class="p-3 mb-0 text-center">
          Proceso 100% online. Sin papeleo ni costos ocultos
        </p>
      </div>
    </div>
    <p>¡Solo necesitas tu cédula y WhatsApp!</p>
    <button
      class="bnt-primary col-12 col-lg-10 col-xl-5 mx-auto"
      @click="payAddi()"
    >
      Pagar con Addi
    </button>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      errorPaymentAddi: '',
    }
  },
  computed: {
    ...mapState('cartAnonimo', ['dataCart']),
  },
  methods: {
    async payAddi() {
      this.$store.state.loaderClover = true
      let data = new FormData()
      data.append('token', this.$route.params.tokenCart)
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.errorPaymentAddi = ''
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/addi/compra',
        data: data,
      })
        .then(response => {
          GA4AddPaymentAnonymous('Addi')
          GA4PurchaseAnonymous()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loaderClover = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 406) {
            this.$bvModal.show('modal-error-406')
            this.msgModalExcepcion406 = err.response.data.errors.bono_regalo[0]
            this.btnModalExcepcion406 = 'addi'
          } else {
            this.errorPaymentAddi = err.response.data.response.msg
          }
        })
    },
  },
}
</script>
