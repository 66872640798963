<template>
  <div class="payment-efecty-baloto">
    <p class="mb-4 text-center">
      El código para pago se mostrará al finalizar la compra. Ten en cuenta que
      el plazo máximo para realizar el pago en cualquier punto <b>Efecty</b> o
      pago en banco es de 48 horas, pasado este plazo tu pedido será cancelado
      automáticamente.* Recuerda que el pago mínimo es de $20.000 pesos.
    </p>
    <div class="col-12 col-lg-10 col-xl-5 mx-auto d-flex flex-wrap mx-auto">
      <button class="bnt-primary w-100 mb-4" @click="sendData()">
        Pagar con Efecty
      </button>
      <div v-if="errorPayment">
        <span class="text-error">{{ msgerrorPaymentCupo }}</span>
      </div>
    </div>
    <p style="font-size: 12px" class="text-center">
      No demores en pagar, solo podemos reservarte los productos cuando el pago
      se acredite
    </p>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
export default {
  data() {
    return {
      paymentSelect: 'efecty',
      errorPayment: false,
      msgerrorPaymentCupo: '',
    }
  },
  computed: {
    ...mapState('cartAnonimo', ['dataCart', 'esFE']),
  },
  methods: {
    async sendData() {
      let data = new FormData()
      this.$store.state.loaderClover = true
      sendFBQEvent(this, 'InitiateCheckout', {})
      data.append('token', this.$route.params.tokenCart)
      data.append('pedido_fe_dian', this.esFE)
      data.append('ticketId', this.paymentSelect)
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/payu/cargar_efectivo',
        data: data,
      })
        .then(response => {
          GA4AddPaymentAnonymous('Efecty')
          GA4PurchaseAnonymous()
          sendFBQEvent(this, 'Purchase', {
            value: response.data.response.pedido.carrito.carrito_total,
            currency: 'COP',
            contents: response.data.response.productos_purchase,
            content_ids: response.data.response.productos_purchase.map(
              e => e.id,
            ),
            event_id:
              response.data.response.pedido.pedido.pedido_reference_sale,
            content_type: 'product',
          })
          // this.$gtag.event('add_payment_info', {
          //   coupon: this.dataCart.cupon,
          //   currency: 'COP',
          //   items: this.cart,
          //   paymentType: 'Efecty',
          //   value: this.dataCart.carrito_subtotal,
          // })
          this.$store.state.cartAnonimo.dataSummaryPayment =
            response.data.response
          this.$store.state.loaderClover = false
        })
        .catch(e => {
          this.errorPayment = true
          this.$store.state.loaderClover = false
          if (e.response.status == 423) {
            // this.$store.state.dataModal423.descripcion =
            //   e.response.data.errors.carrito[0]
            // this.$store.state.dataModal423.routeName =
            //   e.response.data.redireccionar_catalogo &&
            //   e.response.data.redireccionar_catalogo == 1
            //     ? 'Store'
            //     : 'PaymentEmail'
            // this.$store.state.showModal423 = true
            this.$bvModal.show('modal-products-sold-out')
          } else if (e.response.status == 500) {
            this.msgerrorPaymentCupo =
              'Parece que algo salió mal, por favor intenta más tarde.'
          } else if (e.response.status == 406) {
            this.$store.state.cartAnonimo.msgModalExcepcion406 =
              e.response.data.errors.bono_regalo[0]
            this.$store.state.cartAnonimo.btnModalExcepcion406 = 'cupo'
          } else if (e.response.data.message) {
            this.msgerrorPaymentCupo = e.response.data.message
          } else {
            let message = ''
            for (const property in e.response.data.errors) {
              message = message + e.response.data.errors[property] + ' '
            }
            this.msgerrorPaymentCupo = message
          }
        })
    },
  },
}
</script>
