<template>
  <div class="conatiner-checkout-summary">
    <data-summary-payment
      :data-response="dataSummaryPayment.pedido"
      :products="dataSummaryPayment.pedido.productos"
    />
  </div>
</template>
<script>
import DataSummaryPayment from '@/components/checkout/DataSummaryPayment.vue'
import { mapState, mapActions } from 'vuex'
export default {
  components: { DataSummaryPayment },
  props: {
    stepPayment: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      range: null,
      mensaje: '',
      sendedPoll: false,
      styleRange: [
        'left: 14px;',
        'left: calc(9% + 14px);',
        'left: calc(19% + 13px);',
        'left: calc(30% + 2px);',
        'left: calc(39% + 5px);',
        'left: calc(50% - 4px);',
        'left: calc(60% - 8px);',
        'left: calc(70% - 10px);',
        'left: calc(80% - 15px);',
        'left: calc(90% - 18px);',
        'left: calc(100% - 26px);',
      ],
      errorEncuesta: false,
    }
  },
  computed: {
    ...mapState('cartAnonimo', [
      'dataSummaryPayment',
      'errorSurvey',
      'msgErrorSurvey',
    ]),
    valueRatingMovil() {
      return this.styleRange[this.range]
    },
  },
  created() {
    window.scrollTo(0, 0)
    // this.$gtag.event('purchase', {
    //   affiliation: 'Clover',
    //   coupon: this.dataSummaryPayment.pedido.carrito.cupon_codigo,
    //   currency: 'COP',
    //   items: this.dataSummaryPayment.productos,
    //   transaction_id: this.dataSummaryPayment.pedido.carrito.carrito_id,
    //   shipping: this.dataSummaryPayment.pedido.carrito.carrito_envio,
    //   value: this.dataSummaryPayment.pedido.carrito.carrito_subtotal,
    // })
    // window.sendinblue.identify(this.dataSummaryPayment.pedido.carrito.cliente_correo);
    // window.sendinblue.track(
    //     "purchase_clover", {
    //         "email": this.dataSummaryPayment.pedido.carrito.cliente_correo
    //     }, {
    //         "id": this.dataSummaryPayment.pedido.pedido_id,
    //         "data":{
    //             "revenue": this.dataSummaryPayment.carrito.carrito_total,
    //             "venta_cruzada": this.dataSummaryPayment.dataTrackPurchaseSendinblue.venta_cruzada,
    //             "venta_genero": this.dataSummaryPayment.dataTrackPurchaseSendinblue.genero_id,
    //             "items": this.dataSummaryPayment.dataTrackPurchaseSendinblue.items_carrito,
    //             "items_complementarios": this.dataSummaryPayment.dataTrackPurchaseSendinblue.items_complementarios,
    //         }
    //     }
    // );
  },
  beforeDestroy() {
    window.localStorage.removeItem('tokenCart')
    this.$store.state.cart.dataSummaryPayment = null
    this.$store.state.cart.dataCart = null
    this.$store.state.cart.productsCart = null
  },
  methods: {
    ...mapActions('cartAnonimo', ['RegisterPollCheckout']),
    async registerPoll() {
      this.errorEncuesta = false
      if (this.range) {
        await this.RegisterPollCheckout({
          pedido_id: this.dataSummaryPayment.pedido.pedido
            .pedido_reference_sale,
          puntaje: this.range,
          mensaje: this.mensaje,
        }).then(() => {
          if (!this.errorSurvey) {
            this.sendedPoll = true
          }
        })
      } else {
        this.errorEncuesta = true
      }
    },
  },
}
</script>
