<template>
  <div class="container-checkout-data-shipping">
    <div class="col-12 mb-4 d-flex pe-0 pe-md-3">
      <span
        v-if="dataCart.direcciones.length > 1"
        class="go-back-dir"
        @click="$emit('backDir')"
        >Volver</span
      >
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          class="label-input"
          :style="selectDep ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Departamento</label
        >
        <select
          v-model="selectDep"
          type="number"
          :class="invalidSelectDep ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('dep')"
          @focus="invalidSelectDep = false"
        >
          <option :value="null">Departamento</option>
          <option
            v-for="(item, index) in departamentos"
            :key="index"
            :value="item.codigo"
          >
            {{
              item.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </option>
        </select>
        <span v-if="invalidSelectDep" class="inavalida-input"
          >Campo requerido</span
        >
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          class="label-input"
          :style="selectMun ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Ciudad</label
        >
        <select
          v-model="selectMun"
          type="Ciudad"
          :class="invalidSelectMun ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('mun')"
          @focus="invalidSelectMun = false"
        >
          <option :value="null">Ciudad</option>
          <option
            v-for="(item, index2) in arrayMun"
            :key="index2"
            :value="item.codigo"
          >
            {{
              item.descripcion
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </option>
        </select>
        <span v-if="invalidSelectMun" class="inavalida-input"
          >Campo requerido</span
        >
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          class="label-input"
          :style="dir ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Dirección</label
        >
        <input
          v-model="dir"
          type="text"
          :class="invalidDir ? 'invalid-error' : ''"
          placeholder="Dirección"
          autocomplete="address-line1"
          @blur="validateBlur('dir')"
          @focus="invalidDir = false"
        />
        <span v-if="invalidDir" class="inavalida-input">{{
          dir ? 'Dirección invalida' : 'Campo requerido'
        }}</span>
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          class="label-input"
          :style="barrio ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Barrio</label
        >
        <input
          v-model="barrio"
          type="text"
          placeholder="Barrio"
          autocomplete="address-line2"
        />
      </div>
    </div>
    <div class="col-12 px-2 px-xl-3 mb-4">
      <div class="input-simple-clover">
        <label
          class="label-input"
          :style="observaciones ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Observaciones</label
        >
        <input
          v-model="observaciones"
          type="text"
          placeholder="Observaciones"
        />
      </div>
    </div>
    <div class="col-12 col-md-6 px-2 px-xl-3 mb-4 ms-auto">
      <button class="bnt-primary w-100" @click="validateData()">
        Ir a pagar <img src="@/assets/icons/arrow-right.svg" />
      </button>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  props: {
    isEditData: {
      type: Boolean,
      required: false,
    },
    dataEditDir: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      selectDep: null,
      invalidSelectDep: false,
      arrayMun: [],
      selectMun: null,
      invalidSelectMun: false,
      dir: '',
      invalidDir: false,
      barrio: '',
      observaciones: '',
      idDir: '',
    }
  },
  computed: {
    ...mapState('cartAnonimo', ['dataCart']),
    ...mapState('user', ['departamentos']),
    validateDir() {
      return this.dir.length > 4
    },
  },
  watch: {
    selectDep() {
      if (this.selectDep) {
        this.selectMun = null
        this.arrayMun = this.departamentos.find(
          e => e.codigo == this.selectDep,
        )?.ciudades
      } else {
        this.arrayMun = []
      }
    },
    departamentos() {
      if (this.selectDep) {
        this.arrayMun = this.departamentos.find(
          e => e.codigo == this.selectDep,
        )?.ciudades
      } else {
        this.arrayMun = []
      }
    },
  },
  async mounted() {
    if (this.isEditData) {
      this.selectDep = this.dataEditDir.selectDep
      this.dir = this.dataEditDir.dir
      this.barrio = this.dataEditDir.barrio
      this.observaciones = this.dataEditDir.observaciones
      setTimeout(() => (this.selectMun = this.dataEditDir.selectMun), 100)
    } else if (this.dataCart.direcciones.length == 0) {
      this.dir = this.dataCart.cliente_direccion
      this.barrio = this.dataCart.cliente_barrio
      this.observaciones = this.dataCart.observaciones
      this.selectDep = this.dataCart.cliente_departamento
        ? this.dataCart.cliente_departamento
        : null
      setTimeout(
        () =>
          (this.selectMun = this.dataCart.cliente_ciudad
            ? this.dataCart.cliente_ciudad
            : null),
        100,
      )
    }
    if (this.departamentos.length == 0) {
      await this.getCiudades()
    }
  },
  methods: {
    ...mapActions('cartAnonimo', ['updateInfoCart']),
    ...mapActions('user', ['getCiudades']),
    validateBlur(input) {
      if (input == 'dep') {
        this.invalidSelectDep = !this.selectDep
      } else if (input == 'mun') {
        this.invalidSelectMun = !this.selectMun
      } else if (input == 'dir') {
        this.invalidDir = !this.validateDir
      }
    },
    async validateData() {
      if (this.validateDir && this.selectDep && this.selectMun) {
        let data = {
          step: '3',
          cliente_correo: this.dataCart.cliente_correo,
          cliente_nombre: this.dataCart.cliente_nombre,
          cliente_apellidos: this.dataCart.cliente_apellidos,
          tipodoc: this.dataCart.tipodoc,
          cliente_documento: this.dataCart.cliente_documento,
          cliente_celular: this.dataCart.cliente_celular,
          direccion_id: this.idDir,
          cliente_direccion: this.dir,
          cliente_ciudad: this.selectMun,
          cliente_departamento: this.selectDep,
          cliente_barrio: this.barrio,
          observaciones: this.observaciones,
        }
        await this.updateInfoCart(data).then(() => {
          if (!this.errorAddToCart) {
            this.$emit('changeStep', 4)
            // this.$gtag.event('add_shipping_info', {
            //   coupon: this.dataCart.cupon,
            //   currency: 'COP',
            //   items: this.productsCart,
            //   shipping_tier: this.selectMun,
            //   value: this.dataCart.carrito_total,
            // })
          }
        })
      } else {
        this.invalidSelectDep = !this.selectDep
        this.invalidSelectMun = !this.selectMun
        this.invalidDir = !this.validateDir
      }
    },
  },
}
</script>
