<template>
  <div>
    <div class="content-input-cupo mb-3">
      <input
        v-model="cupon"
        class="col ps-3"
        placeholder="Cupón de descuento"
      />
      <button class="bnt-secundary" @click="addCuponCart()">Validar</button>
    </div>
    <div v-if="errorCupon">
      <span class="text-error">{{ msgErrorCupon }}</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      cupon: '',
    }
  },
  computed: {
    ...mapState('cartAnonimo', ['msgErrorCupon', 'errorCupon', 'dataCart']),
  },
  methods: {
    ...mapActions('cartAnonimo', ['addCupon']),
    async addCuponCart() {
      await this.addCupon({ cupon: this.cupon })
      if (this.dataCart.cupon.tooltip_titulo) {
        this.$bvModal.show('modal-cupon')
      }
    },
  },
}
</script>
