<template>
  <div>
    <skeleton-checkout v-if="loaderBag" />
    <div v-else-if="dataCart" class="container-checkout">
      <div
        v-if="productsCart.find(p => p.status == 0)"
        class="container-steps-checkout mb-4 col-12 col-md-7 col-xl-8 pe-0 pe-md-3"
      >
        <div class="alert-product-spent">
          <p>
            Uno o más productos de tu bolsa están agotados, revisa tus productos
            antes de finalizar la compra.
          </p>
        </div>
      </div>
      <div
        v-if="!dataSummaryPayment"
        class="container-steps-checkout col-12 col-md-7 col-xl-8 pe-0 pe-md-3"
      >
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <span
              class="number-step"
              :class="stepActual > 1 ? 'active fw-bold' : ''"
              >01</span
            >
            <p class="title-step fw-bold">Correo electrónico</p>
            <span
              v-if="stepActual > 1"
              class="email-head-step-1 order-3 order-lg-2"
            >
              {{ dataCart.cliente_correo }}
            </span>
            <div class="ms-auto order-2 order-lg-3">
              <span v-if="false">Cerrar sesión</span>
              <span
                v-else-if="stepActual != 1 && stepGeneral > 1 && !dataUser"
                class="action-step"
                @click="stepActual = 1"
                >Editar</span
              >
            </div>
          </div>
          <div v-if="stepActual == 1" class="body-step-checkout">
            <checkout-email
              @changeStep="stepActual = 2"
              @maskData="shoeModalMaskData = true"
            />
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <span
              class="number-step"
              :class="stepActual > 2 ? 'active fw-bold' : ''"
              >02</span
            >
            <p class="title-step fw-bold">Mis datos personales</p>
            <span
              v-if="stepActual > 2 && stepGeneral > 2"
              class="action-step"
              @click="stepActual = 2"
              >Editar</span
            >
            <div v-if="stepActual > 2" class="w-100 ps-5">
              <p class="mb-1 mt-3">
                {{ dataCart.tipodoc }}: {{ dataCart.cliente_documento }}
              </p>
              <p class="mb-1">Nombre: {{ dataCart.cliente_nombre }}</p>
              <p class="mb-1">Celular: {{ dataCart.cliente_celular }}</p>
            </div>
          </div>
          <div v-if="stepActual == 2" class="body-step-checkout">
            <checkout-personal-data @changeStep="stepActual = 3" />
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <span
              class="number-step"
              :class="stepActual > 3 ? 'active fw-bold' : ''"
              >03</span
            >
            <p class="title-step fw-bold">Datos de envío</p>
            <span
              v-if="stepActual > 3 && stepGeneral > 3"
              class="action-step"
              @click="
                stepActual = 3
                crearEditarDir = dataCart.direcciones > 0 ? true : false
              "
              >Editar</span
            >
            <div v-if="stepActual > 3" class="w-100 ps-5">
              <p class="mb-1 mt-3 two-wrap-text">
                {{ dataCart.cliente_direccion }}, {{ dataCart.cliente_barrio }}
              </p>
              <p class="mb-1">
                {{
                  dataCart.cliente_ciudad_texto
                    .toLowerCase()
                    .replace(/\^w/, d => d.toUpperCase())
                }}
              </p>
              <p class="fw-bold">Tiempo aproximado de entrega:</p>
              <div
                class="d-flex flex-wrap p-3 align-items-center justify-content-between w-100 mb-4"
                style="background-color: white"
              >
                <p class="col-md mb-2 mb-md-0">
                  Hasta {{ dataCart.envio.dias_entrega }} días hábiles, lo
                  recibes entre el
                  {{ dataCart.envio.fechasEnvio.fechaInicio }} y el
                  {{ dataCart.envio.fechasEnvio.fechaFinal }}.
                </p>
                <p class="mb-0 col-md-2 col-xl-1 fw-bold text-end">
                  ${{
                    new Intl.NumberFormat('de-DE').format(dataCart.envio.total)
                  }}
                </p>
                <div v-if="dataCart.infoEstrategiaVentas" class="w-100 mt-2">
                  <div
                    class="p-2 px-3 rounded"
                    style="background-color: #f6bdc8; display: inline-block;"
                  >
                    <a
                      class="link-envio-gratis"
                      style="color: #212529;"
                      target="_blank"
                      :href="
                        dataCart.infoEstrategiaVentas.link_pagina_condiciones
                      "
                    >
                      Aplica <b>envío gratis</b>. Ver términos y condiciones.
                    </a>
                  </div>
                </div>
              </div>
              <div v-if="dataCart.carrito_editar == 0" class="col-12 mb-4">
                <button
                  class="bnt-outline w-100"
                  @click="
                    isEditData = false
                    crearEditarDir = true
                    isEditData = false
                    dataEditDir = {}
                    stepActual = 3
                  "
                >
                  Entregar en otra dirección
                </button>
              </div>
            </div>
          </div>
          <div v-if="stepActual == 3" class="body-step-checkout">
            <div
              v-if="dataCart.direcciones.length > 0 && !crearEditarDir"
              class="container-cards-direcctions-checkout"
            >
              <div class="w-100">
                <div
                  v-for="(dir, index) in dataCart.direcciones"
                  :key="index"
                  class="card-direcction"
                  :style="
                    dirSelected == dir.direccion_id
                      ? 'border: 2px solid black;'
                      : ''
                  "
                >
                  <input
                    v-model="dirSelected"
                    :value="dir.direccion_id"
                    type="radio"
                  />
                  <div
                    class="data-dir-card"
                    @click="dirSelected = dir.direccion_id"
                  >
                    <p class="dir-card">
                      <b>{{ dir.direccion }}</b>
                    </p>
                    <p class="bar-card">{{ dir.barrio }}</p>
                    <p class="obs-card">{{ dir.observaciones }}</p>
                    <p class="city-card">{{ dir.ciudad_texto }}</p>
                    <p class="price-card">{{ dir.valor_envio }}</p>
                  </div>
                  <span
                    class="edit-dir-card"
                    @click="
                      dataUser
                        ? editDataDir(dir)
                        : [
                            $bvModal.show('modal-login'),
                            ($store.state.user.isLogin = true),
                          ]
                    "
                    >Editar</span
                  >
                </div>
              </div>
              <div class="d-flex flex-wrap pt-4">
                <div
                  v-if="dataCart.carrito_editar == 1"
                  class="col-12 col-md-6 pe-0 pe-md-3 mb-4 mb-md-0"
                >
                  <button
                    class="bnt-outline w-100"
                    @click="
                      isEditData = false
                      crearEditarDir = true
                      isEditData = false
                      dataEditDir = {}
                    "
                  >
                    Agregar una nueva dirección
                  </button>
                </div>
                <div class="me-auto" />
                <div class="col-12 col-md-6 ps-0 ps-md-3">
                  <button class="bnt-primary w-100" @click="updateSelectDir()">
                    Ir a pagar <img src="@/assets/icons/arrow-right.svg" />
                  </button>
                </div>
              </div>
            </div>
            <checkout-data-shipping
              v-else
              :data-editDir="dataEditDir"
              :is-edit-data="isEditData"
              @backDir="
                isEditData = false
                crearEditarDir = false
                dataEditDir = {}
              "
              @changeStep="stepActual = 4"
            />
          </div>
        </div>
        <div class="container-step-checkout">
          <div class="head-step-checkout">
            <span class="number-step">04</span>
            <p class="title-step fw-bold">Pago</p>
          </div>
          <div v-if="stepActual == 4" class="body-step-checkout">
            <checkout-payment />
          </div>
        </div>
      </div>
      <div v-else class="container-steps-checkout col-12 pe-0 pe-md-3">
        <checkout-summary />
      </div>
      <div
        v-if="!dataSummaryPayment"
        class="container-summary-checkout col-12 col-md-5 col-xl-4 px-3 ps-md-0"
      >
        <div class="container-data-summary-checkout">
          <div style="display: flex; flex-wrap: wrap">
            <div class="w-100">
              <h3 class="text-center mb-3">Resumen de compra</h3>
              <div class="flex-sumarry-data-payment pt-3">
                <input-cupon v-if="!dataCart.cupon.codigo" />
                <table class="mt-4 w-100">
                  <tbody>
                    <tr class="mb-3 d-flex justify-content-between">
                      <td class="text-right">
                        <strong>Subtotal: </strong>
                      </td>
                      <td class="ps-4">
                        <span>
                          ${{
                            new Intl.NumberFormat('de-DE').format(
                              dataCart.carrito_subtotal,
                            )
                          }}
                        </span>
                      </td>
                    </tr>
                    <tr class="mb-3 d-flex justify-content-between">
                      <td class="text-right">
                        <strong>Envío: </strong>
                      </td>
                      <td class="ps-4">
                        <template v-if="dataCart.infoEstrategiaVentas">
                          <span class="fw-bold text-capitalize">
                            {{ dataCart.infoEstrategiaVentas.tipo_estrategia }}
                            <img
                              v-b-popover.hover="
                                dataCart.infoEstrategiaVentas
                                  .tooltip_descripcion
                              "
                              class="mx-2"
                              src="@/assets/icons/icon-info.svg"
                              alt="icono información cupon"
                            />
                          </span>
                        </template>
                        <template v-else>
                          <span
                            v-if="parseInt(dataCart.carrito_envio) > 0"
                            class="fw-bold"
                            >${{
                              new Intl.NumberFormat('de-DE').format(
                                dataCart.carrito_envio,
                              )
                            }}</span
                          >
                          <span v-else class="envio-calc fw-bold"
                            >Por calcular</span
                          >
                        </template>
                      </td>
                    </tr>
                    <tr
                      v-if="dataCart.cupon.codigo"
                      class="bg-cupon-code d-flex justify-content-between mb-3"
                    >
                      <td>
                        <p class="d-flex mb-2">
                          Código: {{ dataCart.cupon.codigo }}
                          <img
                            v-b-popover.hover="
                              dataCart.cupon.tooltip_descripcion
                            "
                            class="mx-2"
                            src="@/assets/icons/icon-info.svg"
                            alt="icono información cupon"
                          />
                        </p>
                        <p class="delet-cupo" @click="deleteCupon()">
                          Eliminar
                        </p>
                      </td>
                      <td>
                        <span
                          ><b
                            >${{
                              new Intl.NumberFormat('de-DE').format(
                                dataCart.cupon.valor,
                              )
                            }}</b
                          ></span
                        >
                      </td>
                    </tr>
                    <tr
                      v-if="dataCart.cupon.codigo"
                      class="mb-3 d-flex justify-content-between"
                    >
                      <td>
                        <span>Descuentos:</span>
                      </td>
                      <td>
                        <span
                          >-${{
                            new Intl.NumberFormat('de-DE').format(
                              dataCart.cupon.valor,
                            )
                          }}</span
                        >
                      </td>
                    </tr>
                    <div v-if="dataCart.carrito_bono_codigo" class="mb-3">
                      <b-card
                        id="cupon"
                        class="w-100"
                        body-class="py-1 my-0 px-2"
                      >
                        <div class="mb-1 d-flex justify-content-between">
                          <span>Saldo a favor:</span>
                          <b class="float-right">
                            -${{
                              new Intl.NumberFormat('de-DE').format(
                                dataCart.carrito_bono_valor_aplicado,
                              )
                            }}
                          </b>
                        </div>
                        <p class="mb-0">{{ dataCart.carrito_bono_codigo }}</p>
                        <p class="text-end mb-0">
                          <span
                            class="option-remove-cupo"
                            @click="removeGiftCard()"
                            >Remover</span
                          >
                        </p>
                      </b-card>
                    </div>
                    <tr class="mb-3 d-flex justify-content-between">
                      <td class="text-right">
                        <b>Total: </b>
                      </td>
                      <td class="ps-4">
                        <span class="fw-bold"
                          >${{
                            new Intl.NumberFormat('de-DE').format(
                              dataCart.carrito_total,
                            )
                          }}</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  class="container-products-checkout"
                  style="overflow-y: auto"
                  :style="showProducts ? 'top: 0' : 'top: 100%'"
                >
                  <div
                    class="btn-show-product-payment"
                    @click="showProducts = !showProducts"
                  >
                    <p class="mb-0" style="transition: all 0.3s linear">
                      Vas a comprar
                      {{
                        groupProductsCart.length > 1
                          ? `${groupProductsCart.length} productos`
                          : '1 producto'
                      }}
                    </p>
                    <img
                      class="ms-3"
                      style="transition: all 0.3s linear; filter: invert(1)"
                      :style="
                        showProducts
                          ? 'transform: rotate(90deg);'
                          : 'transform: rotate(-90deg);'
                      "
                      src="@/assets/icons/arrow-simple.svg"
                    />
                  </div>
                  <div
                    class="container-product-checkout"
                    :style="'overflow-y: auto;'"
                  >
                    <card-product-detail
                      v-for="(product, index) in groupProductsCart"
                      :key="index"
                      :data-product="product"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              style="position: sticky; bottom: 0; width: 100%"
              class="btn-show-product-payment"
              @click="showProducts = !showProducts"
            >
              <p class="mb-0" style="transition: all 0.3s linear">
                {{
                  showProducts
                    ? 'Ver menos'
                    : 'Vas a comprar ' +
                      (groupProductsCart.length > 1
                        ? `${groupProductsCart.length} productos`
                        : '1 producto')
                }}
              </p>
              <img
                class="ms-3"
                style="transition: all 0.3s linear; filter: invert(1)"
                :style="
                  showProducts
                    ? 'transform: rotate(90deg);'
                    : 'transform: rotate(-90deg);'
                "
                src="@/assets/icons/arrow-simple.svg"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      v-model="shoeModalMaskData"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="position-relative p-4 text-center">
        <h4>Hola!</h4>
        <p class="fs-6">
          Este correo ya se encuentra registrado, completaremos el formulario
          automaticamente con tus datos.
        </p>
        <button
          class="bnt-primary"
          @click="
            shoeModalMaskData = false
            validateStepcheckout()
          "
        >
          Continuar con la compra
        </button>
      </div>
    </b-modal>
    <b-modal
      id="modal-error-payment"
      hide-footer
      hide-header
      centered
      no-close-on-backdrop
    >
      <div class="container-modal-mask-data">
        <div class="icon-error-modal text-center">
          <svg
            class="mb-3"
            xmlns="http://www.w3.org/2000/svg"
            width="33.003"
            height="33.041"
            viewBox="0 0 33.003 33.041"
          >
            <g
              id="Icon_feather-check-circle"
              data-name="Icon feather-check-circle"
              transform="translate(-1.497 -1.473)"
            >
              <path
                id="Trazado_2544"
                data-name="Trazado 2544"
                d="M33,16.62V18A15,15,0,1,1,24.105,4.29"
                fill="none"
                stroke="#F67777"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
              <path
                id="Trazado_2545"
                data-name="Trazado 2545"
                d="M27.213,6,18,15.222"
                transform="translate(-4.106 6.896)"
                fill="none"
                stroke="#F67777"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
              <path
                id="Trazado_2578"
                data-name="Trazado 2578"
                d="M9.213,0,0,9.223"
                transform="translate(23.112 12.901) rotate(90)"
                fill="none"
                stroke="#F67777"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="3"
              />
            </g>
          </svg>
          <h3 class="mb-2">No fue posible procesar tu pago</h3>
        </div>
        <p class="text-center">
          Tu compra no ha finalizado, parece que hubo un problema en la
          autorización de la transacción. <br /><br />
          Intenta realizar el pago nuevamente.
        </p>
        <button
          class="bnt-primary"
          @click="$bvModal.hide('modal-error-payment')"
        >
          Seleccionar otro medio de pago
        </button>
      </div>
    </b-modal>
    <b-modal
      v-model="showModal423"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
    >
      <div class="position-relative p-4 text-center">
        <h3>
          <b>{{ dataModal423.title }}</b>
        </h3>
        <p class="fs-6">{{ dataModal423.descripcion }}</p>
        <button class="bnt-primary" @click="validate423()">Entendido</button>
      </div>
    </b-modal>
    <b-modal id="modal-cupon" centered hide-header hide-footer>
      <div>
        <h3 class="text-center">{{ dataCart?.cupon.tooltip_titulo }}</h3>
        <p class="text-center">{{ dataCart?.cupon.tooltip_descripcion }}</p>
        <p class="text-center">
          <a :href="dataCart?.cupon.link_pagina_condiciones" target="_blank">
            ver mas
          </a>
        </p>
        <div class="d-flex">
          <button
            class="bnt-primary mx-auto fw-bold px-5"
            @click="$bvModal.hide('modal-cupon')"
          >
            Entendido
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CheckoutEmail from '@/components/checkoutAnonimo/CheckoutEmail.vue'
import SkeletonCheckout from '@/components/checkoutAnonimo/SkeletonCheckout.vue'
import CheckoutPersonalData from '@/components/checkoutAnonimo/CheckoutPersonalData.vue'
import CheckoutDataShipping from '@/components/checkoutAnonimo/CheckoutDataShipping.vue'
import CheckoutPayment from '@/components/checkoutAnonimo/CheckoutPayment.vue'
import CheckoutSummary from '@/components/checkoutAnonimo/CheckoutSummary.vue'
import InputCupon from '@/components/checkoutAnonimo/InputCupon.vue' /** Falta cambiar */
import CardProductDetail from '@/components/Templates/CardProductDetail.vue'

import { mapActions, mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4CheckoutBeginAnonymous, GA4AddShipingAnonymous } from '@/GA4/events'
export default {
  components: {
    CheckoutEmail,
    CheckoutPersonalData,
    CheckoutDataShipping,
    CheckoutPayment,
    InputCupon,
    CardProductDetail,
    CheckoutSummary,
    SkeletonCheckout,
  },
  data() {
    return {
      stepActual: 0,
      stepGeneral: 0,
      showProducts: false,
      dirSelected: null,
      crearEditarDir: false,
      isEditData: false,
      dataEditDir: {},
      shoeModalMaskData: false,
    }
  },
  computed: {
    ...mapState('cartAnonimo', [
      'dataCart',
      'productsCart',
      'vptCart',
      'dataSummaryPayment',
      'loaderBag',
      'errorAddToCart',
    ]),
    ...mapState('user', ['dataUser']),
    ...mapState(['showModal423', 'dataModal423']),
    groupProductsCart() {
      let groupProduct = []
      this.productsCart.forEach(product => {
        if (
          !groupProduct.find(
            e =>
              e.producto_sku == product.producto_sku &&
              e.referencia.color_id == product.referencia.color_id &&
              e.referencia.talla.id == product.referencia.talla.id &&
              e.producto_aplica_estrategia == 0,
          ) ||
          product.producto_aplica_estrategia == 1
        ) {
          let dataTemp = product
          dataTemp.countProduct = 1
          groupProduct.push(dataTemp)
        } else {
          groupProduct = groupProduct.filter(e => {
            if (
              e.producto_sku == product.producto_sku &&
              e.referencia.color_id == product.referencia.color_id &&
              e.referencia.talla.id == product.referencia.talla.id &&
              e.producto_aplica_estrategia == product.producto_aplica_estrategia
            ) {
              e.countProduct += 1
            }
            return e
          })
        }
      })
      return groupProduct
    },
  },
  watch: {
    dataCart() {
      if (this.dataCart) {
        this.watchRouterCheckout()
        if (this.dataCart.carrito_editar == 1) {
          if (this.dataCart.carrito_editar != 0) {
            if (this.stepActual != 1) {
              this.validateStepcheckout()
            }
          } else if (this.stepActual != 1) {
            this.validateStepcheckout()
          }
        }
        this.dirSelected = this.dataCart.direccion_id
        // this.$gtag.event('begin_checkout', {
        //   coupon: this.dataCart.cupon,
        //   currency: 'COP',
        //   items: this.cart,
        //   value: this.dataCart.carrito_subtotal,
        // })
      }
    },
    stepActual() {
      let query = Object.assign({}, this.$route.query)
      if (this.stepActual == 1) {
        this.$router
          .replace({ name: 'PaymentEmailAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepActual == 2) {
        this.$router
          .replace({ name: 'PaymentProfileAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepActual == 3) {
        this.$router
          .replace({ name: 'PaymentShippingAddressAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepActual == 4) {
        this.$router
          .replace({ name: 'PaymentAnonymous', query: query })
          .catch(() => {})
      } else if (this.stepActual == 5) {
        this.$router
          .replace({ name: 'PaymentSummaryAnonymous', query: query })
          .catch(e => {
            console.log(e)
          })
      }
      if (this.stepActual > 1) {
        window.sendinblue?.identify(this.dataCart.cliente_correo)
      }
    },
    loaderBag() {
      this.validateStepcheckout()
    },
  },
  created() {
    sendFBQEvent(this, 'AddPaymentInfo', {})
  },
  async mounted() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover-simple'
    this.$store.state.layouts.menuLayoutClover = 'menu-layout-simple'
    await this.getProductsCart().then(() => {
      if (this.errorAddToCart) {
        this.$router.replace({ name: 'Home' })
      } else {
        this.watchRouterCheckout()
      }
    })
    document.title = 'Finaliza tu compra | Clover'
    this.validateStepcheckout()
    if (this.dataCart) {
      this.dirSelected = this.dataCart.direccion_id
      // this.$gtag.event('begin_checkout', {
      //   coupon: this.dataCart.cupon,
      //   currency: 'COP',
      //   items: this.cart,
      //   value: this.dataCart.carrito_subtotal,
      // })
    }
    if (this.$route.params.showmodalPaymentDecline) {
      this.$bvModal.show('modal-error-payment')
    }
  },
  beforeDestroy() {
    this.$store.state.layouts.footerLayoutClover = 'footer-clover'
    this.$store.state.layouts.menuLayoutClover = 'menu-clover'
  },
  methods: {
    ...mapActions('cartAnonimo', [
      'deleteCupon',
      'updateInfoCart',
      'getProductsCart',
      'removeGiftCard',
    ]),
    editDataDir(data) {
      this.dataEditDir = {
        dir: data.direccion,
        barrio: data.barrio,
        observaciones: data.observaciones,
        selectDep: data.departamento_id,
        selectMun: data.ciudad_id,
        idDir: data.ciudad_id,
      }
      this.isEditData = true
      this.crearEditarDir = true
    },
    async updateSelectDir() {
      let data = this.dataCart.direcciones.find(
        e => e.direccion_id == this.dirSelected,
      )
      let info = {
        step: 3,
        cliente_correo: this.dataCart.cliente_correo,
        cliente_nombre: this.dataCart.cliente_nombre,
        cliente_apellidos: this.dataCart.cliente_apellidos,
        tipodoc: this.dataCart.tipodoc,
        cliente_documento: this.dataCart.cliente_documento,
        cliente_celular: this.dataCart.cliente_celular,
        direccion_id: data.direccion_id,
        cliente_direccion: data.direccion,
        cliente_ciudad: data.ciudad_id,
        cliente_departamento: data.departamento_id,
        cliente_barrio: data.barrio,
        observaciones: data.observaciones,
      }
      await this.updateInfoCart(info)
      this.validateStepcheckout()
    },
    validateStepcheckout() {
      if (
        this.dataCart &&
        this.dataCart.cliente_direccion &&
        this.dataCart.cliente_ciudad &&
        this.dataCart.cliente_departamento
      ) {
        this.stepActual = 4
        this.stepGeneral = 4
        GA4AddShipingAnonymous()
        GA4CheckoutBeginAnonymous()
      } else if (
        this.dataCart &&
        this.dataCart.cliente_nombre &&
        this.dataCart.tipodoc &&
        this.dataCart.cliente_celular
      ) {
        this.stepActual = 3
        this.stepGeneral = 3
      } else if (this.dataCart && this.dataCart.cliente_correo) {
        this.stepActual = 2
        this.stepGeneral = 2
        GA4CheckoutBeginAnonymous()
      } else {
        this.stepActual = 1
        this.stepGeneral = 1
      }
      if (this.dataCart && this.dataCart.direcciones.length <= 1) {
        this.crearEditarDir = true
      }
    },
    watchRouterCheckout() {
      let query = Object.assign({}, this.$route.query)
      if (
        this.dataCart &&
        this.dataCart.cliente_direccion &&
        this.dataCart.cliente_ciudad &&
        this.dataCart.cliente_departamento
      ) {
        this.$router
          .replace({ name: 'PaymentAnonymous', query: query })
          .catch(() => {})
      } else if (
        this.dataCart &&
        this.dataCart.cliente_nombre &&
        this.dataCart.tipodoc &&
        this.dataCart.cliente_celular
      ) {
        this.$router
          .replace({ name: 'PaymentShippingAddressAnonymous', query: query })
          .catch(() => {})
      } else if (this.dataCart && this.dataCart.cliente_correo) {
        this.$router
          .replace({ name: 'PaymentProfileAnonymous', query: query })
          .catch(() => {})
      } else {
        this.$router
          .replace({ name: 'PaymentEmailAnonymous', query: query })
          .catch(() => {})
      }
    },
    validate423() {
      if (this.dataModal423.routeName == 'PaymentEmail') {
        location.reload()
      } else {
        this.$router.push({ name: this.dataModal423.routeName })
      }
    },
  },
}
</script>
