<template>
  <div class="container-payment-pse text-center">
    <p class="w-100">
      <b>Selecciona la entidad financiera</b>
    </p>
    <p class="mb-4">
      Selecciona la entidad financiera para continuar con el pago, recuerda que
      debes tener una cuenta habilitada para realizar pagos por internet.
    </p>
    <div class="col-12 col-lg-10 col-xl-5 mx-auto mb-4">
      <div class="input-simple-clover">
        <label
          :class="invalidBank ? 'invalid' : ''"
          class="label-input"
          :style="bank ? 'top: -14px;' : 'opacity: 0; z-index: -1;'"
          >Entidad bancaria</label
        >
        <select
          v-model="bank"
          :class="invalidBank ? 'invalid-error' : ''"
          class="w-100"
          @blur="validateBlur('bank')"
          @focus="invalidBank = false"
        >
          <option :value="null">Entidad bancaria</option>
          <option
            v-for="(banco, index) in bancos"
            :key="index"
            :value="banco.pseCode"
          >
            {{
              banco.description
                .toLowerCase()
                .replace(/^\w/, d => d.toUpperCase())
            }}
          </option>
          <span v-if="invalidBank" class="inavalida-input">{{
            bank ? 'Apellido invalido' : 'Campo requerido'
          }}</span>
        </select>
        <span v-if="invalidBank" class="inavalida-input">Campo requerido</span>
      </div>
    </div>
    <div class="col-12 col-lg-10 col-xl-5 mx-auto mb-4">
      <button class="bnt-primary w-100" @click="sendData()">
        Pagar con PSE
      </button>
    </div>
    <p style="font-size: 12px" class="text-center w-100">
      Los pagos con este medio se acreditan como máximo en 1 hora
    </p>
    <div v-if="errorPayment">
      <span class="text-error">{{ msgerrorPaymentCupo }}</span>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { sendFBQEvent } from '@/fbq'
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
export default {
  data() {
    return {
      bancos: [],
      bank: null,
      invalidBank: false,
      errorPayment: false,
      msgerrorPaymentCupo: '',
    }
  },
  computed: {
    ...mapState('cartAnonimo', ['esFE']),
    validateBank() {
      return this.bank
    },
  },
  async mounted() {
    await this.axios({
      method: 'GET',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: '/payment/payu/listar_bancos_pse',
    })
      .then(response => {
        this.bancos = response.data.bancos
      })
      .catch(e => {
        console.log(e)
      })
  },
  methods: {
    async sendData() {
      if (this.validateBank) {
        let data = new FormData()
        this.$store.state.loaderClover = true
        sendFBQEvent(this, 'InitiateCheckout', {})
        data.append('token', this.$route.params.tokenCart)
        data.append('pedido_fe_dian', this.esFE)
        data.append('docFinancialInstitution', this.bank)
        await this.axios({
          method: 'POST',
          headers: { 'content-type': 'application/x-www-form-urlencoded' },
          url: '/payment/payu/cargar_pse',
          data: data,
        })
          .then(response => {
            GA4AddPaymentAnonymous('PSE')
            GA4PurchaseAnonymous()
            location.href = response.data.response.url
          })
          .catch(e => {
            this.errorPayment = true
            this.$store.state.loaderClover = false
            if (e.response.status == 423) {
              // this.$store.state.dataModal423.descripcion =
              //   e.response.data.errors.carrito[0]
              // this.$store.state.dataModal423.routeName =
              //   e.response.data.redireccionar_catalogo &&
              //   e.response.data.redireccionar_catalogo == 1
              //     ? 'Store'
              //     : 'PaymentEmail'
              // this.$store.state.showModal423 = true
              this.$bvModal.show('modal-products-sold-out')
            } else if (e.response.status == 500) {
              this.msgerrorPaymentCupo =
                'Parece que algo salió mal, por favor intenta más tarde.'
            } else if (e.response.status == 406) {
              this.$store.state.cartAnonimo.msgModalExcepcion406 =
                e.response.data.errors.bono_regalo[0]
              this.$store.state.cartAnonimo.btnModalExcepcion406 = 'cupo'
            } else if (e.response.data.message) {
              this.msgerrorPaymentCupo = e.response.data.message
            } else {
              let message = ''
              for (const property in e.response.data.errors) {
                message = message + e.response.data.errors[property] + ' '
              }
              this.msgerrorPaymentCupo = message
            }
          })
      } else {
        this.invalidBank = true
      }
    },
    validateBlur(input) {
      if (input == 'bank') {
        this.invalidBank = !this.validateBank
      }
    },
  },
}
</script>
