<template>
  <div class="container-paymen-sistecredito">
    <p class="text-center mb-4">
      Al seleccionar este medio de pago ten en cuenta que: Podrás realizar pagos
      desde $30.000, debes tener tu crédito al día, debes tener tu celular a la
      mano para enviarte el código de verificación y serás redirigido a la
      página de Sistecrédito.
    </p>
    <button
      class="bnt-primary col-12 col-lg-10 col-xl-5 mx-auto mb-3"
      @click="paySistecredito()"
    >
      Pagar con Sistecrédito
    </button>
    <div v-if="errorPaymentSistecredito" class="w-100">
      <span class="text-error">{{ errorPaymentSistecredito }}</span>
    </div>
  </div>
</template>
<script>
import { GA4AddPaymentAnonymous, GA4PurchaseAnonymous } from '@/GA4/events'
import { sendFBQEvent } from '@/fbq'
export default {
  data() {
    return {
      errorPaymentSistecredito: '',
    }
  },
  methods: {
    async paySistecredito() {
      this.$store.state.loaderClover = true
      let data = new FormData()
      data.append('token', this.$route.params.tokenCart)
      sendFBQEvent(this, 'InitiateCheckout', {})
      this.errorPaymentSistecredito = ''
      await this.axios({
        method: 'POST',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        url: '/payment/sistecredito/compra',
        data: data,
      })
        .then(response => {
          GA4AddPaymentAnonymous('Sistecredito')
          GA4PurchaseAnonymous()
          location.href = response.data
        })
        .catch(err => {
          this.$store.state.loaderClover = false
          if (err.response.status == 423) {
            this.$bvModal.show('modal-products-sold-out')
          } else if (err.response.status == 500) {
            this.errorPaymentSistecredito =
              'Parece que algo salió mal, por favor intenta más tarde.'
          } else if (err.response.status == 406) {
            this.$store.state.cart.msgModalExcepcion406 =
              err.response.data.errors.bono_regalo[0]
            this.$store.state.cart.btnModalExcepcion406 = 'cupo'
          } else if (err.response.data.message) {
            this.errorPaymentSistecredito = err.response.data.message
          } else {
            console.log('Hola?')
            let message = ''
            for (const property in err.response.data.errors) {
              message = message + err.response.data.errors[property] + ' '
            }
            this.errorPaymentSistecredito = message
          }
        })
    },
  },
}
</script>
